import React, { useCallback, useState } from "react";

import { Auth } from "aws-amplify";

export default function Logout() {
  const [inProgress, setInProgress] = useState(false);
  const handleClick = useCallback(async () => {
    setInProgress(true);
    try {
      await Auth.signOut();
    } finally {
      setInProgress(false);
    }
  }, [setInProgress]);
  return (
    <button onClick={handleClick} type="button" disabled={inProgress}>
      Sign Out
    </button>
  );
}
