import React, { useState, useCallback } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";

export default function XHRForm() {
  const [uri, setUri] = useState(
    "https://www.mobsuccess.com/webservices/rest/validate-auth"
  );
  const [result, setResult] = useState("");
  const handleChangeURI = useCallback((e) => setUri(e.target.value), [setUri]);
  const handleClick = useCallback(async () => {
    setResult(`Calling URI: ${uri}…`);
    try {
      const { data } = await axios.get(uri, {
        //withCredentials: true,
        headers: {
          authorization: `Bearer jwt:${
            (
              await Auth.currentSession()
            ).accessToken.jwtToken
          }`,
        },
      });
      setResult(
        typeof data === "object" ? JSON.stringify(data, null, 4) : data
      );
    } catch (e) {
      setResult(`Failed: ${e}`);
    }
  }, [setResult, uri]);
  return (
    <div>
      <p>
        URI:{" "}
        <input value={uri} onChange={handleChangeURI} style={{ width: 600 }} />
      </p>
      <p>
        <button type="button" onClick={handleClick}>
          Perform
        </button>
      </p>
      <pre>{result}</pre>
    </div>
  );
}
