import React from "react";

import "./App.css";
import CognitoAuthentication from "./components/CognitoAuthentication";
import Logout from "./components/Logout";
import XHRForm from "./components/XHRForm";

function App() {
  return (
    <CognitoAuthentication>
      <p>Successfully logged in</p>
      <Logout />
      <h3>Perform XHR</h3>
      <XHRForm />
    </CognitoAuthentication>
  );
}

export default App;
