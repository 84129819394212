import React, { useMemo, useEffect, useState, useCallback } from "react";

import { Hub, Auth } from "aws-amplify";

function CognitoAuthentication({ children }) {
  const [preChecking, setPreChecking] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [session, setSession] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasFailed, setHasFailed] = useState(false);
  const handleChangeEmail = useCallback(
    (e) => setEmail(e.target.value),
    [setEmail]
  );
  const handleChangePassword = useCallback(
    (e) => setPassword(e.target.value),
    [setPassword]
  );
  useEffect(() => {
    Hub.listen("auth", (data) => {
      console.log("Hub auth event", data);
      switch (data.payload.event) {
        case "signIn":
          setHasFailed(false);
          setLoggedIn(true);
          break;
        case "signOut":
          setHasFailed(false);
          setLoggedIn(false);
          break;
      }
    });
  }, [setHasFailed, setLoggedIn]);
  useEffect(async () => {
    try {
      console.log("Checking for previous authentication");
      const session = await Auth.currentSession();
      console.log("Login succeeded", session);
      setHasFailed(false);
      setLoggedIn(true);
    } catch (e) {
      console.log("We are currently not authenticated");
    } finally {
      setPreChecking(false);
    }
  }, [setPreChecking]);
  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      setInProgress(true);
      try {
        console.log("Login", { email, password });
        console.log(Auth.configure());

        const user = await (async () => {
          try {
            return await Auth.signIn(email);
          } catch (e) {
            console.error(e);
            setHasFailed(true);
            throw e;
          }
        })();
        console.log("Cognito user", user);
        await Auth.sendCustomChallengeAnswer(user, password);
        console.log("Custom challenge sent");
        try {
          setSession(await Auth.currentSession());
        } catch (e) {
          setHasFailed(true);
          return;
        }
        setHasFailed(false);
        setLoggedIn(true);
      } finally {
        setInProgress(false);
      }
    },
    [email, password, setLoggedIn, setInProgress]
  );
  useMemo(() => console.log("Session", session), [session]);
  return preChecking ? null : loggedIn ? (
    children
  ) : (
    <form>
      <p>
        Email:{" "}
        <input
          placeholder="email@mobsuccess.com"
          value={email}
          onChange={handleChangeEmail}
        />
      </p>
      <p>
        Password:{" "}
        <input
          type="password"
          value={password}
          onChange={handleChangePassword}
        />
      </p>
      {hasFailed && <p style={{ color: "red" }}>Wrong login or password</p>}
      <input
        disabled={inProgress}
        type="submit"
        onClick={handleLogin}
        value="Login"
      />
    </form>
  );
}

export default CognitoAuthentication;
